export const arrowHeight = 60;
export const arrowWidth = 52;

const commonStyle = {
    width: `${arrowWidth}px`,
    height: `${arrowHeight}px`,
};

export default () => ({
    '&:before': {
        content: 'none',
    },
    '&.slick-prev': commonStyle,
    '&.slick-next': commonStyle,
});
