export const wrapper = ({ hasBackground, opacity }) => ({
    textAlign: 'center',
    padding: hasBackground ? 0 : '8rem 0 0 0',
    background: '#040e18',

    '& .Text': {
        maxWidth: '22rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '1.5rem',

        min768px: {
            marginBottom: '3.5rem',
        },
    },

    '& .Link': {
        marginBottom: '6rem',

        min768px: {
            marginBottom: '10.5rem',
        },
    },
});

export const bg = ({ backgroundFullscreen }) => {
    if (backgroundFullscreen) {
        return {
            display: 'flex',
            padding: '8rem 0 0 0',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            min768px: {
                padding: '10.5rem 0 0 0',
                boxSizing: 'border-box',
            },
            min992px: {
                minHeight: 'unset',
            },
            min1200px: {
                minHeight: '100vh',
            },
            zIndex: 10,
            /*'&:before': {
                transition: 'opacity 0.8s ease !important',
                opacity: `${opacity.opacity} !important`,
            },*/
        };
    } else {
        return {
            zIndex: 10,
            padding: '8rem 0 0 0',
            backgroundPosition: '50% 0',
            backgroundSize: 'contain',
            min768px: {
                padding: '20.5rem 0 14rem 0',
            },
        };
    }
};
