import React from 'react';
import * as PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { combineRules } from 'fela';

import * as componentStyles from './Section.style';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({
    backgroundImage,
    backgroundFullscreen,
    backgroundFadeIn,
    onBackgroundLoad,
    customStyle,
    ...props
}) => {
    return (
        <FelaComponent hasBackground={Boolean(backgroundImage)} style={componentStyles.wrapper} as="section">
            <FelaComponent
                style={combineRules(componentStyles.bg, customStyle)}
                backgroundFullscreen={backgroundFullscreen}
            >
                {({ className }) => (
                    <BackgroundImage
                        key={backgroundImage.src} /* force re-render when user change language */
                        Tag="section"
                        className={className}
                        fluid={backgroundImage}
                        backgroundColor={`#040e18`}
                        fadeIn={backgroundFadeIn}
                        onLoad={onBackgroundLoad}
                    >
                        {props.children}
                    </BackgroundImage>
                )}
            </FelaComponent>
        </FelaComponent>
    );
};

BackgroundSection.propTypes = {
    backgroundImage: PropTypes.object,
    backgroundFullscreen: PropTypes.bool,
    customStyle: PropTypes.func,
    onBackgroundLoad: PropTypes.func,
    backgroundFadeIn: PropTypes.oneOf([true, false, 'soft']),
};

BackgroundSection.defaultProps = {
    backgroundImage: null,
    backgroundFullscreen: false,
    customStyle: () => {},
    onBackgroundLoad: () => {},
    backgroundFadeIn: true,
};

export default BackgroundSection;
