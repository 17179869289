import React from 'react';
import PropTypes from 'prop-types';
import { combineRules } from 'fela';
import { FelaComponent } from 'react-fela';

import style from './Arrow.style';

const Arrow = ({ arrowIcon, arrowStyle, ...props }) => {
    return (
        <FelaComponent style={combineRules(arrowStyle, style)}>
            {({ className }) => (
                <button type="button" className={`${className} ${props.className}`} onClick={props.onClick}>
                    <img src={arrowIcon} alt="" style={props.style} />
                </button>
            )}
        </FelaComponent>
    );
};

Arrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
    arrowStyle: PropTypes.func.isRequired,
    arrowIcon: PropTypes.string.isRequired,
};

Arrow.defaultProps = {
    className: '',
    style: {},
    onClick: () => {},
};

export default Arrow;
